import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Articulate Storyline?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "An articulate Storyline is a tool for building interactive tools used to build online courses and e-learning modules. It is also used to produce quizzes, simulations, screen recording, drag-and-drop interactions and several other e-learning objects that users can interact with."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Does Articulate Storyline Do For Businesses?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "If you are into mobile and accessible courses or want to train your staff using e-learning, you will be able to deliver them with enhanced interactivity faster with Articulate Storyline.Codestaff can help."
        }
    }
}

const ArticulateStoryline = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Articulate Storyline Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Articulate Storyline Developers. Top
                                    companies and start-ups choose Codestaff Articulate Storyline experts
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Articulate Storyline Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE ARTICULATE STORYLINE DEVELOPERS' banner='/articulate-storyline.png' bannerAlt='articulate storyline banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default ArticulateStoryline