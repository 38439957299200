import React from 'react'
import Layout from '../components/layout'
import ArticulateStoryline from '../components/categories/articulate-storyline'
import Seo from '../components/seo'

const ArticulateStorylinePage = ({ location }) => {
  return (
    <Layout location={location}>
      <ArticulateStoryline />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Articulate Storyline Freelancers | Codestaff'
  const description =
    'Hire the best Articulate Storyline freelancers at Codestaff. Get the top 1% of Articulate Storyline professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ArticulateStorylinePage
